<template>
  <div>
    <el-card
      class="box-card"
      shadow="never"
      :style="{ height: cardHeight + 'px' }"
    >
     
      <el-row>
          <el-form ref="formData" :model="formData" :label-width="formLabelWidth">
            <el-form-item label="充值对象" >
             <div class="block">
                
                <el-cascader
                    :options="options"
                    :props="props"
                    size="mini"
                    v-model="formData.members"
                    clearable></el-cascader>
                </div>
            </el-form-item>
            <el-form-item label="充值数额" >
                <el-col :span="10">
                    <el-input v-model="formData.score" autocomplete="off" :size="allSize" :min="1" type="number"></el-input>
                </el-col>
            </el-form-item>
           <el-form-item>
                <el-button :size="allSize" @click="list=[]">取 消</el-button>
                <el-button type="primary" :size="allSize" @click="queding">确 定</el-button>
                <el-button type="primary" :size="allSize" @click="piliang" v-if="list.length>0">批量执行</el-button>
           </el-form-item>
          </el-form>
      </el-row>
      <!----表单内容---->
      <el-row class="top">
        <el-table
          :data="list"
          :size="allSize"
          :height="tableHeight"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
           style="width: 100%"
           border
           v-loading="loading"
        >
         
          <el-table-column prop="realname" label="姓名"></el-table-column>
          <el-table-column prop="bumen" label="部门"></el-table-column>
          <el-table-column prop="bm" label="单号"></el-table-column>
          <el-table-column prop="score" label="积分"></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">  
              <el-button @click.native.prevent="delData(scope.$index, scope.row.id)" type="text" size="small">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
        
      </el-row>
    </el-card>


  </div>
</template>

<script>
let _this;
import selectImage from "@/components/image/image.vue";
import EditorBar from "@/components/wangEnduit.vue";
import filterView from "@/components/filterView.vue";
export default {
  name: "Fuwu",
  components: { selectImage, EditorBar, filterView },
  computed: {
    cardHeight() {
      return window.innerHeight * 1 - 130;
    },
    tableHeight() {
      return window.innerHeight * 1 - 130 - 120;
    },
  },
  data() {
    return {
      showimage: false,
      selectField: "",
      list: [],
      isClear: true,
      total: 0,
      imagetype: "one",
      url: "/User/",
      title: "批量充值",
      allSize: "mini",
      loading: false,
      showKeyword: false,
      showWhere: false,
      showCreate: false,
      whereTitle: "",
      isfilter: false,
      whereArray: [],
      page: 1,
      size: 20,
      keyword: "",
      formData: {members:[],score:0},
      formLabelWidth: "120px",
      props: { multiple: true },
      options:[]
    };
  },
  mounted() {
    _this = this;
    _this.getOtherData();
    _this.keyupSubmit();
  },
  methods: {
    piliang(){
    const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    _this.postRequest("/user/piliang",_this.list)
        .then((resp) => {
          if (resp.code == 200) {
            _this.list = [];
            _this.formData.members=[];
            _this.formData.score = 0;
            loading.close();
            _this.$message({
                type: "success",
                message: resp.msg,
            });

          }else{
            loading.close();
            _this.$message({
                type: "error",
                message: resp.msg,
            });
          }
        })
        .catch((e) => {
            loading.close();
          _this.$message({
            type: "error",
            message: e.message,
          });
        });
        
    },
    queding(){
        _this.postRequest("/user/piliangchongzhi",_this.formData)
        .then((resp) => {
          if (resp.code == 200) {
                _this.list = resp.data
          }else{
             _this.$message({
                type: "error",
                message: resp.msg,
            });
          }
        })
        .catch((e) => {
          _this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    getOtherData() {
      _this.postRequest("/user/getBumenList")
        .then((resp) => {
          if (resp.code == 200) {
            _this.options= resp.data
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.message,
          });
        });
    },
    getData() {
      _this.loading = true;
      _this.postRequest(
          _this.url + "index?page=" + _this.page + "&size=" + _this.size,
          { keyword: this.keyword, whereArray: this.whereArray }
        )
        .then((resp) => {
          _this.list = resp.data;
          _this.total = resp.count;
          _this.loading = false;
        })
        .catch((e) => {
          _this.$message({
            type: "error",
            message: e.message,
          });
        });
    },

    editData(id) {
      _this.formData = { title: "", content: null };
      if (id != 0) {
        _this.getInfo(id);
      }
      _this.showCreate = true;
    },
    getInfo(id) {
      _this.getRequest(_this.url + "read?id=" + id).then((resp) => {
        if (resp) {
          _this.formData = resp.data;
        }
      });
    },
    saveData() {
      _this.$refs["formData"].validate((valid) => {
        if (valid) {
          _this.postRequest(_this.url + "save", _this.formData).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: resp.msg,
              });
              _this.getData();
              _this.showCreate = false;
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    delData(index, id) {
      _this
        .$confirm("是否删除该信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
            _this.list.splice(index, 1);
        })
        .catch(() => {
          _this.$message({
            type: "error",
            message: "取消删除!",
          });
        });
    },

    handleFieldChange(field, value, id, type) {
      var data = { field: field, value: value, id: id };

      _this.postRequest(_this.url + "changeField", data).then((resp) => {
        if (resp.code == 200) {
          this.$notify({
            title: "成功",
            message: resp.msg,
            type: "success",
          });
        } else {
          _this.$notify({
            title: "错误",
            type: "error",
            message: resp.msg,
          });
        }
      });
    },

    /**操作筛选条件start */
    oncloseWhere() {
      _this.showWhere = false;
    },
    closeFilter() {
      _this.showWhere = false;
      _this.isfilter = false;
      _this.whereArray = [];
      _this.getData();
    },
    clearKeyword(value) {
      this.showKeyword = false;
    },

    openKeyword() {
      this.showKeyword = true;
    },

    inputAllKeyword(keyword) {
      _this.keyword = keyword;
      _this.whereArray = [];
      _this.page  = 1
      _this.getData();
    },

    showFitlers() {
      _this.showKeyword = false;
      _this.keyword = "";
      _this.showWhere = !this.showWhere;
    },

    onSeachData(value, isfilter) {
      if (isfilter) {
        _this.isfilter = true;
        _this.whereTitle = value.title;
        _this.whereArray = value.whereArray;
        _this.showWhere = false;
      } else {
        _this.whereArray = value;
      }
      _this.page  = 1
      _this.getData();
    },

    handleSizeChange(val) {
      _this.size = val;
      _this.getData();
    },
    handleCurrentChange(val) {
      _this.page = val;
      _this.getData();
    },
    /**操作筛选条件end */

    /**操作图片方法start */
    onSelectImage(value) {
      if (_this.imagetype == "more") {
        value.forEach((item) => {
          _this.formData[_this.selectField].push(item);
        });
      } else {
        console.log(value);
        _this.formData[_this.selectField] = value;
      }

      _this.showimage = false;
    },
    openImage(field, imagetype) {
      _this.showimage = true;
      _this.imagetype = imagetype;
      _this.selectField = field;
    },
    removeImage(field, imagetype, index) {
      if (imagetype == "one") {
        _this.formData[field] = "";
      } else {
        _this.formData[field].splice(index, 1);
      }
    },
    /**操作图片方法end */
    //监听键盘
    keyupSubmit() {
      document.onkeydown = (e) => {
        let _key = window.event.keyCode;
        //13 回车
        if (_key === 13) {
          console.log(this.search.keyword);
        }
      };
    },
  },
};
</script>

<style lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.top {
  margin-top: 10px;
}

.el-dropdown-link:hover {
  color: #409eff;
}

.select_input {
  border: none !important;
  box-shadow: none !important;
  padding: 0px;
}

/* switch按钮样式 */
.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  margin-right: 9px;
}
/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  margin-left: 5px;
}
/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.imgDiv {
  display: inline-block;
  position: relative;
}

.imgDiv .delete {
  position: absolute;
  top: -4px;
  left: 75px;
  right: 0px;
  width: 25px;
  height: 25px;
  display: none;
}
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.dropdown {
  cursor: pointer;
}
.drop {
  padding: 10px;
}
.input {
  width: 100px;
  margin: 0 auto;
  width: 100%;
}

.project-dropdown {
  //设置高度才能显示出滚动条 !important
  height: 300px;
  overflow: auto;
}
.project-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.project-dropdown::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.pic {
  width: 50px;
  height: 50px;
}
</style>
